// Globals
import { memo, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from '@/ui';
import { Image, UploadProps } from 'antd';
import {
  StyledBlock,
  StyledButtons,
  StyledFigure,
  StyledFigureHoverMask,
  StyledImageUpload,
  StyledInfo,
  StyledZoomIcon,
} from './MediaUploadStyles';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';

// Helpers
import { fileDownload, getImageDimensions } from '@/utils';
import { determineMediaType } from '../../helpers';

type MediaType = 'image' | 'video' | null;

type IImageUploadProps = UploadProps & {
  value?: string | File;
  onChange?: (file?: File | Blob | null, mediaUrl?: string) => void;
  accept?: string;
  recommendedSize?: { width: number; height: number };
  recommendedFormats?: string;
};

const MediaUpload = ({
  onChange,
  value,
  accept = '.png .svg, .mp4',
  recommendedSize,
  recommendedFormats,
  ...props
}: IImageUploadProps) => {
  const { t } = useTranslation(['common']);
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number } | null>(null);
  const [mediaType, setMediaType] = useState<MediaType>(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [initialMedia, setInitialMedia] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (initialMedia && mediaType === 'image') {
      getImageDimensions(initialMedia).then(setImageDimensions);
    }
  }, [initialMedia, mediaType]);

  useEffect(() => {
    if (!value) return;
    const image = typeof value === 'string' ? value : URL.createObjectURL(value);
    if (typeof value === 'string') {
      setMediaType(determineMediaType(image));
    }
    setInitialMedia(image);
    videoRef.current?.load();
    return () => {
      setInitialMedia(null);
      if (typeof value === 'string') return;
      URL.revokeObjectURL(image);
    };
  }, [value]);

  const handleSelectFile = useCallback(
    async (file: Blob) => {
      const reader = new FileReader();
      setMediaType(file.type.startsWith('image/') ? 'image' : file.type.startsWith('video/') ? 'video' : null);
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        const url = reader.result as string;
        onChange?.(file, url);
      };
      return false;
    },
    [onChange],
  );
  const handlePreview = async (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!initialMedia) return;
    setPreviewImage(initialMedia);
    setPreviewOpen(true);
  };

  const handlePrevent = async (e: SyntheticEvent) => {
    e.stopPropagation();
    if (typeof value !== 'string') return;
    try {
      const imageBlob = await fetch(value).then((res) => res.blob());
      const fileName = value.split('/').at(-1) ?? 'image';

      fileDownload(imageBlob, fileName);
    } catch {}
  };

  const handleDelete = async (e: SyntheticEvent) => {
    e.stopPropagation();
    setInitialMedia(null);
    onChange?.(null);
  };

  return (
    <StyledBlock>
      {recommendedSize && (
        <StyledInfo>
          {t('recommended_size', {
            width: recommendedSize?.width,
            height: recommendedSize?.height,
          })}
        </StyledInfo>
      )}
      {recommendedFormats && (
        <StyledInfo>
          {t('recommended_image_formats', {
            formats: recommendedFormats,
          })}
        </StyledInfo>
      )}
      {initialMedia && mediaType === 'image' && (
        <StyledInfo>
          {t('label_dimensions', {
            width: imageDimensions?.width || 0,
            height: imageDimensions?.height || 0,
          })}
        </StyledInfo>
      )}
      <StyledImageUpload beforeUpload={handleSelectFile} showUploadList={false} accept={accept} {...props}>
        {initialMedia && mediaType === 'image' && (
          <StyledFigure onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <img
              src={initialMedia}
              alt="preview"
              onClick={handlePreview}
              onKeyDown={handlePreview}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
            />
            {isHovered && (
              <StyledFigureHoverMask>
                <StyledZoomIcon />
              </StyledFigureHoverMask>
            )}
          </StyledFigure>
        )}
        {initialMedia && mediaType === 'video' && (
          <StyledFigure>
            <video controls width="400" height="500" ref={videoRef}>
              <source src={initialMedia} />
            </video>
          </StyledFigure>
        )}
        <StyledButtons>
          {typeof value === 'string' && <Button onClick={handlePrevent} icon={<DownloadOutlined />} />}
          {!!initialMedia && <Button onClick={handleDelete} icon={<DeleteOutlined />} />}
          <Button>{t('btn_upload_new')}</Button>
        </StyledButtons>
      </StyledImageUpload>
      {previewImage && mediaType === 'image' && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </StyledBlock>
  );
};
export default memo(MediaUpload);
