type MediaType = 'image' | 'video' | null;

const determineMediaType = (url: string): MediaType => {
  const extension = url.split('.').pop()?.toLowerCase();
  if (['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp', 'avif', 'bmp', 'ico', 'tiff'].includes(extension || ''))
    return 'image';
  if (['mp4', 'webm', 'ogg', 'mkv', 'flv', 'mov', 'avi', 'wmv', 'm4v', 'm2v', 'mpg'].includes(extension || ''))
    return 'video';
  return null;
};

export default determineMediaType;
