// Types
import { PageContent, PageSEO, PageStatus, PageType } from '@/modules/Pages/typedefs';

export enum PagesEndpoints {
  ROOT = '/pages',
}

// ============== Interfaces ==============

export interface IBasePage {
  id: string;
  title: string;
  type: PageType;
  status: PageStatus;
  createdAt: string;
  updatedAt: string;
}

export interface IFullPage extends IBasePage {
  seo: PageSEO;
  content: PageContent;
  schema: any;
}

// ============== DTOs ==============

export type IGetPagesDTO = {
  channelId: number;
};

export type IGetPageDTO = {
  channelSlug: string;
  pageType: PageType;
  isRaw: boolean;
};

export type UpdatePageStatusDTO = {
  status: PageStatus;
};

export type IUpdatePageDto = {
  type: PageType;
  images?: File[];
  title?: string;
  seo?: Partial<PageSEO>;
  content?: Partial<PageContent>;
  channelIds?: number[];
};

// ============== Response ==============

export type IGetPagesResponse = IBasePage[];
export type IGetPageResponse = IFullPage;
export type IUpdatePageStatusResponse = IBasePage;
export type IUpdatePageResponse = IFullPage;
