import { COLORS } from '@/theme';
import { ColorPicker } from 'antd';
import { styled } from 'styled-components';

export const StyledColorPicker = styled(ColorPicker)`
  &.ant-color-picker-trigger {
    border: none;
    background-color: transparent;
    .ant-color-picker-color-block {
      border-radius: 50%;
      border: 1px solid ${COLORS.BLACK};
    }
    .ant-color-picker-trigger-text {
      margin-inline-start: 1rem;
    }
  }
` as typeof ColorPicker;
