import { Image } from 'antd';
import { styled } from 'styled-components';

export const StyledImage = styled(Image)`
  &&& + .ant-image-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 0.5rem;
    }

    &&& .ant-spin-nested-loading {
      width: 100%;
      height: 100%;
    }
  }
`;

export const StyledImagePlaceholder = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
`;

export const StyledBlock = styled.div`
  background-color: darkgray;
`;
