// Globals
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import { Form, useForm } from '@/components';

// Modules
import { selectChannel } from '@/modules/Channels';

// Store
import { getPages, selectCurrentPage, updatePage } from '../../../../feature';
import { useAppDispatch } from '@/store';

// Helpers
import { useScrollToErrorField } from '../../hooks/useScrollToErrorField';
import { prepareFormDataToTransfer } from '../../utils/prepareFormFieldsToTransfer';
import { getInitialValues } from './utils';

interface Props {
  children: React.ReactNode;
  setActiveTab: (key: string) => void;
  activeTab: string;
}

export const UpdatePageFormProvider: FC<Props> = ({ children, activeTab, setActiveTab }) => {
  const [form] = useForm();
  const currentPage = useSelector(selectCurrentPage);
  const channel = useSelector(selectChannel);
  const dispatch = useAppDispatch();
  const { scrollToErrorField } = useScrollToErrorField(form, activeTab, setActiveTab);

  useEffect(() => {
    form.setFieldsValue(getInitialValues(currentPage));
    form.resetFields();
  }, [currentPage, form]);

  const handleFinish = async (values: any) => {
    if (!currentPage || !channel) return;
    const data = prepareFormDataToTransfer(currentPage, values);

    try {
      await dispatch(
        updatePage({
          pageId: currentPage.id,
          data,
        }),
      ).unwrap();
      await dispatch(
        getPages({
          channelId: channel?.id,
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form
      form={form}
      initialValues={getInitialValues(currentPage)}
      onFinishFailed={scrollToErrorField}
      onFinish={handleFinish}
    >
      {children}
    </Form>
  );
};
