import { useTranslation } from 'react-i18next';
import {
  useGetEmailRule,
  useGetNumbersOnlyRule,
  useGetPhoneRule,
  useGetRequiredRule,
  useLengthValidationRule,
} from '@/hooks';
import { GeneralSettingsFormFields } from '../helpers/types';

const useGeneralSettingsFormRules = () => {
  const { t } = useTranslation(['validations']);

  const phoneRule = useGetPhoneRule({ t });
  const lengthRule = useLengthValidationRule({ t, max: 100 });
  const emailRule = useGetEmailRule({ t });
  const requiredRule = useGetRequiredRule({ t });
  const numbersOnlyRule = useGetNumbersOnlyRule({ t });

  return {
    [GeneralSettingsFormFields.CHANNEL]: [requiredRule],
    [GeneralSettingsFormFields.COPYRIGHT]: [requiredRule],
    [GeneralSettingsFormFields.GOOGLE_TAG_MANAGER]: [requiredRule],
    [GeneralSettingsFormFields.PHONES_CONTACT_US]: [phoneRule],
    [GeneralSettingsFormFields.PHONES_FOOTER]: [phoneRule],
    [GeneralSettingsFormFields.SMTP_PORT]: [requiredRule, numbersOnlyRule],
    [GeneralSettingsFormFields.SMTP_AUTH_PASS]: [requiredRule],
    [GeneralSettingsFormFields.SMTP_AUTH_USER]: [lengthRule, requiredRule],
    [GeneralSettingsFormFields.SMTP_HOST]: [lengthRule, requiredRule],
    [GeneralSettingsFormFields.POPULAR_PRODUCTS]: [requiredRule],
    [GeneralSettingsFormFields.CONTACT_INFO_EMAIL]: [emailRule, requiredRule],
    [GeneralSettingsFormFields.CONTACT_INFO_WORKING_HOURS]: [requiredRule],
    [GeneralSettingsFormFields.CONTACT_INFO_ADDRESS]: [requiredRule],
    [GeneralSettingsFormFields.SUPPORT_INFO_EMAIL]: [emailRule],
    [GeneralSettingsFormFields.SUPPORT_INFO_PHONE]: [phoneRule],
    [GeneralSettingsFormFields.ADMIN_EMAILS]: [
      requiredRule,
      // {
      //   validator: (_: unknown, value: string[]) => {
      //     if (value?.some((email) => !emailRule.pattern.test(email))) {
      //       return Promise.reject(new Error(emailRule.message));
      //     }

      //     return Promise.resolve();
      //   },
      //   message: emailRule.message,
      // },
    ],
  };
};

export default useGeneralSettingsFormRules;
