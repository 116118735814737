import { IFullPage } from '@/models/page.model';

export const getInitialValues = (currentPage: IFullPage | null) => {
  const initialValues = {
    seo: {
      title: currentPage?.seo?.title,
      description: currentPage?.seo?.description,
      keywords: currentPage?.seo?.keywords,
    },
    content: {
      title: currentPage?.title,
      text: currentPage?.content?.text,
      gallery: currentPage?.content?.gallery,
      banner: currentPage?.content?.banner,
      blocks: currentPage?.content?.blocks,
      image: currentPage?.content?.image,
      imageName: currentPage?.content?.imageName,
    },
  };

  return initialValues;
};
