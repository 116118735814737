import { IChannel } from '@/models/channel.model';
import { ITheme } from '@/models/theme.model';
import { DeepPartial } from '@/types';

export enum SettingsEndpoints {
  ROOT = '/settings',
  ADMIN = '/admin',
  UI = '/ui',
}

export const staticSettingsEndpoints = {
  GET_SETTINGS: `${SettingsEndpoints.ROOT}${SettingsEndpoints.ADMIN}`,
  UPDATE_SETTINGS: SettingsEndpoints.ROOT,
  UPDATE_UI_SETTINGS: `${SettingsEndpoints.ROOT}${SettingsEndpoints.UI}`,
  GET_UI_SETTINGS: `${SettingsEndpoints.ROOT}${SettingsEndpoints.UI}`,
};

export const getSettingsEndpoints = ({ id }: { id: string }) => ({
  UPDATE_SETTINGS: `${SettingsEndpoints.ROOT}/${id}`,
});

export interface IGeneralSettings {
  copyright: string;
  googleTagManagement: string;
  adminEmails: string[];
  smtp: EmailConfig;
  contactInfo: ContactInfo;
  supportInfo: SupportInfo;
  phones: {
    footer?: string;
    contactUsPage?: string;
  };
  popularProductIds: string[];
  popularCategoriesIds: string[];
  topPicksOfTheMonthProductIds: string[];
  homePageBlogPostSlug: string;
  channel: IChannel;
  newMachinesCategory: string;
  monthlySpecialCategory: string;
}

export interface EmailConfig {
  host?: string;
  port?: number;
  auth?: {
    user?: string;
    pass?: string;
  };
}

export interface ContactInfo {
  email?: string;
  workingHours?: string;
  address?: string;
}

export interface SupportInfo {
  email?: string;
  phone?: string;
  name?: string;
}

export interface ShippingConfig {
  USA?: number;
  Canada?: number;
  other?: number;
}

export interface ILogo {
  id: string;
  file: {
    url: string;
    id: string;
  };
  metaType: 'headerLogo' | 'footerLogo' | 'emptyStateLogo' | 'mobileHeaderLogo' | 'companyLogo';
}

export interface IFavicon {
  id: string;
  file: {
    url: string;
    id: string;
  };
  metaType: 'favicon48' | 'favicon96' | 'favicon144';
}

export interface ISetting extends IGeneralSettings {
  id: string;
  channelId: number;
  popularProductIds: string[];
  createdAt: string;
  updatedAt: string;
  images: (ILogo | IFavicon)[];
  theme: ITheme;
}

// ============== DTO ==============

export type IGetSettingsDTO = {
  channel: string;
};

export type IUpdateGeneralSettingsDTO = {
  uuid: string;
  smtp?: DeepPartial<EmailConfig>;
  contactInfo?: DeepPartial<ContactInfo>;
  supportInfo?: DeepPartial<SupportInfo>;
  channel?: string;
} & Partial<Omit<IGeneralSettings, 'channel'>>;

export type IUpdateUISettingsDTO = {
  headerLogo?: File;
  footerLogo?: File;
  emptyStateLogo?: File;
  mobileHeaderLogo?: File;
  companyLogo?: File;
  favicon48?: File;
  favicon96?: File;
  favicon144?: File;
  channelId: number;
  theme?: Partial<ITheme>;
};

export type IBulkUpdateGeneralSettingsDTO = {
  smtp?: Partial<EmailConfig>;
  channelIds: number[];
} & Partial<Omit<IGeneralSettings, 'channel'>>;

export type IGetUISettingsDTO = {
  channel: string;
};

// ============== Response ==============

export type IGetSettingsResponse = ISetting;

export type IGetUISettingsResponse = ITheme;

export type IUpdateGeneralSettingsResponse = IGeneralSettings;

export type IUpdateUISettingsResponse = {
  theme: ITheme;
  images: ILogo[];
};

export type IBulkUpdateGeneralSettingsResponse = ISetting[];
