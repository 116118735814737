import { urlRegex } from '@/constants/regex';
import DOMPurify from 'dompurify';

export const parseValidationRulesFromSchema = (schema: Record<string, any>): Record<string, any> => {
  const validations: Record<string, any> = {};

  if (!schema || !schema.properties) {
    return validations;
  }

  for (const key in schema.properties) {
    const property = schema.properties[key];
    if (property.type === 'object') {
      validations[key] = parseValidationRulesFromSchema(property);
    } else if (property.type === 'array' && !property.validations) {
      validations[key] = parseValidationRulesFromSchema(property.items);
    } else {
      const rules = [];

      if (property.validations) {
        if (property.validations.required) {
          rules.push({ required: true, message: `${key} is required` });
        }
        if (property.validations.maxLength) {
          if (key === 'text') {
            rules.push({
              // eslint-disable-next-line @typescript-eslint/no-loop-func
              validator: (_: any, value: any) => {
                const plainText = DOMPurify.sanitize(value, { ALLOWED_TAGS: [] });
                const textLength = plainText.length;

                const isValid = textLength <= property.validations.maxLength;
                return isValid
                  ? Promise.resolve()
                  : Promise.reject(new Error(`${key} must be at most ${property.validations.maxLength} characters`));
              },
            });
          } else {
            rules.push({
              max: property.validations.maxLength,
              message: `${key} must be at most ${property.validations.maxLength} characters`,
              type: property.type === 'array' ? 'array' : undefined,
            });
          }
        }
        if (property.validations.isLink) {
          rules.push({
            pattern: urlRegex,
            message: `${key} must be a valid URL`,
          });
        }
        if (property.validations.allowedTypes) {
          rules.push({
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            validator: (_: any, value: any) => {
              if (!value || typeof value === 'string') {
                return Promise.resolve();
              }
              const anyImagesTypeIsAllowed = property.validations.allowedTypes.includes('image');
              const anyVideoTypeIsAllowed = property.validations.allowedTypes.includes('video');
              let isValid: boolean = false;
              if (anyImagesTypeIsAllowed) {
                isValid = value.type.startsWith('image');
              } else if (anyVideoTypeIsAllowed) {
                isValid = value.type.startsWith('video');
              } else {
                isValid = property.validations.allowedTypes.includes(value.type);
              }
              return isValid
                ? Promise.resolve()
                : Promise.reject(new Error(`${key} must be of type ${property.validations.allowedTypes.join(', ')}`));
            },
          });
        }
        if (property.validations.maxSize) {
          rules.push({
            validator: (_: any, value: any) => {
              if (!value || typeof value === 'string') {
                return Promise.resolve();
              }
              const isValid = value.size <= property.validations.maxSize;
              return isValid
                ? Promise.resolve()
                : Promise.reject(
                    new Error(`${key} must be smaller than ${property.validations.maxSize / 1024 / 1024}MB`),
                  );
            },
          });
        }
      }

      if (rules.length > 0) {
        validations[key] = rules;
      }
    }
  }

  return validations;
};
