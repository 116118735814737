import { IFullPage, IGetPagesResponse, IUpdatePageStatusResponse } from '@/models/page.model';
import { showApiErrors } from '@/utils';
import { PayloadAction, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { getPage, getPages, updatePage, updatePageStatus } from './actionCreators';
import { PagesState, SLICE_NAME, initialState } from './models';

export const pagesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<IFullPage | null>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPages.fulfilled, (state: PagesState, action: PayloadAction<IGetPagesResponse>) => {
        const { payload: pages } = action;
        state.isLoading = false;
        state.pages = pages;
      })
      .addCase(updatePageStatus.fulfilled, (state: PagesState, action: PayloadAction<IUpdatePageStatusResponse>) => {
        const {
          payload: { id, status },
        } = action;
        state.updateStatusIsLoading = false;
        state.pages = state.pages.map((page) => (page.id === id ? { ...page, status } : page));
      })
      .addCase(getPage.fulfilled, (state: PagesState, action: PayloadAction<IFullPage>) => {
        const { payload: currentPage } = action;
        state.currentPage = currentPage;
        state.currentPageIsLoading = false;
      })
      .addCase(updatePage.fulfilled, (state: PagesState) => {
        state.currentPageIsLoading = false;
        state.currentPage = null;
      })
      .addMatcher(isPending(getPage, updatePage), (state: PagesState) => {
        state.currentPageIsLoading = true;
        state.currentPageError = null;
      })
      .addMatcher(isRejected(getPage, updatePage), (state: PagesState, action) => {
        const { error } = action;
        state.currentPageIsLoading = false;
        state.currentPageError = error;
        showApiErrors(error);
      })
      .addMatcher(isPending(getPages), (state: PagesState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected(getPages), (state: PagesState, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        showApiErrors(error);
      })
      .addMatcher(isPending(updatePageStatus), (state: PagesState) => {
        state.updateStatusIsLoading = true;
        state.updateStatusError = null;
      })
      .addMatcher(isRejected(updatePageStatus), (state: PagesState, action) => {
        const { error } = action;
        state.updateStatusIsLoading = false;
        state.updateStatusError = error;
        showApiErrors(error);
      });
  },
});

export const { setCurrentPage } = pagesSlice.actions;
