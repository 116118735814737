import { selectCurrentPage } from '@/modules/Pages/feature';
import { Rule } from 'antd/es/form';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { parseAcceptTypesFromSchema } from '../utils/parseAcceptTypesFromSchema';
import { parseValidationRulesFromSchema } from '../utils/parseValidationRulesFromSchema';
import { useGetRequiredRule } from '@/hooks';
import { useTranslation } from 'react-i18next';

export const usePageValidations = () => {
  const currentPage = useSelector(selectCurrentPage);

  const { t } = useTranslation(['validations']);

  const requiredRule = useGetRequiredRule({ t });

  const staticRules = {
    title: [requiredRule],
  };

  const validationRulesMap = parseValidationRulesFromSchema(currentPage?.schema);
  const allowedFileTypes = parseAcceptTypesFromSchema(currentPage?.schema);

  const getRulesFor = useCallback(
    (path: string[]): Rule[] | undefined => {
      const result = path.reduce<Record<string, any> | Rule[]>((acc, key) => {
        if (acc && typeof acc === 'object') {
          return (acc as Record<string, any>)[key];
        }
        return undefined;
      }, validationRulesMap) as Rule[] | undefined;

      return result;
    },
    [validationRulesMap],
  );

  const getAcceptTypesFor = useCallback(
    (path: string[]): string | undefined => {
      return allowedFileTypes[path.join('.')] || undefined;
    },
    [allowedFileTypes],
  );

  return { getRulesFor, getAcceptTypesFor, staticRules };
};
