import { Spinner } from '@/ui';
import { ImageProps } from 'antd';
import { StyledBlock, StyledImage, StyledImagePlaceholder } from './ImageStyles';

export type IImage = ImageProps;

const Image = (props: IImage) => {
  const { src, alt } = props;

  return (
    <StyledImage
      placeholder={
        <Spinner spinning={true}>
          <StyledImagePlaceholder>
            <StyledBlock />
          </StyledImagePlaceholder>
        </Spinner>
      }
      {...props}
      fallback="/images/thumb.jpg"
      src={src || '/images/thumb.jpg'}
      alt={alt || ''}
    />
  );
};

export default Image;
